import React from 'react'

import "../Styles/Loader.css"


const Loader=()=> {
    return (
        <div id='loader'>
            
            <div class="spinner"> 
                <p className='text-spiner'>Enviando...</p>
             </div>
        </div>
    )
}

export default Loader
