import "../Styles/footer.css"
import instagramLogo from "../archivosGraficos/logosSimples/instagramLogo.png"
import facebookLogo from "../archivosGraficos/logosSimples/facebookLogo.png"
import ubicacionLogo from "../archivosGraficos/logosSimples/ubicacionLogo.png"
import EmailLogo from "../archivosGraficos/logosSimples/emailLogo.png"
import federacionRural from "../archivosGraficos/logosColor/footer-logo_.png"
const footer = () => {
    return (
        <footer className=" mt-5">
            <div className="container">
                <div className=" divCenter">
                    <p className="textoFooterNombre mt-5" >
                        Asociación Agropecuaria de Jóvenes Salto
                    </p>
                    {/* <p className="textoFooterNombre">
                        Federacion Rural de Jovenes
                    </p> */}
                    <img src={federacionRural} alt="" />
                </div>

                <div className="row" >



                    <div className=" divCenter">
                        <span id="divContactanos">Contáctanos</span>
                        <div className="row d-flex justify-content-center">
                            <div className="row col-12 col-md-6 d-flex justify-content-center liena2">
                                <div className="col-6 logoText" >
                                    <a className=" infoEnlaces" href="https://www.instagram.com/aajsalto/?hl=es" >
                                        <img src={instagramLogo} alt="" height="30 px" width="30 px" />
                                        <p className="textoFooter-links"> @aajsalto</p>
                                    </a>
                                </div>

                                <div className="col-6 logoText" >
                                    <a href="https://www.facebook.com/AAJSALTO" className="infoEnlaces">
                                        <img src={facebookLogo} height="30 px" width="30px" alt="" />
                                        <p className="textoFooter-links" > AAj Salto</p>
                                    </a>

                                </div>
                            </div>

                            <div className="row d-flex justify-content-center col-12 col-md-6  linea1">
                                <div className="col-6 logoText-not-link" >
                                    <img src={EmailLogo} alt="" height={"20px"} width="20px" />
                                    <p className="textoFooter " style={{ marginBottom: '5px', marginLeft: '10px' }}> aajasto@hotmail.com</p>
                                </div>

                                <div className="col-6 logoText-not-link">
                                    <img src={ubicacionLogo} alt="" height="30px" width="35px" />
                                    <p className="textoFooter" style={{ marginTop: '13px' }} >Calle Amorín 55, Salto, Uruguay</p>
                                </div>
                            </div>
                        </div>


                    </div>



                </div>
                <div className="d-center p-5">

                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2191.1811086390207!2d-57.96836063632906!3d-31.38696684711496!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95addd0079570431%3A0x90dbf51cf09a9a26!2sAsociaci%C3%B3n%20Agropecuaria%20de%20J%C3%B3venes%20de%20Salto!5e0!3m2!1ses-419!2suy!4v1709978865054!5m2!1ses-419!2suy" id="mapa"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>

            </div>
        </footer>
    )
}
export default footer;