
import React, { useEffect, useState } from "react";

import axios from 'axios';

import Loader from "../components/Loader";


import "../Styles/contactStyles.css"
import instagramLogo from "../archivosGraficos/logosSimples/instagramLogo.png"
import facebookLogo from "../archivosGraficos/logosSimples/facebookLogo.png"
import sponsor1 from "../archivosGraficos/fotosActividadesAASJ/sponsor1.jpg"

const Contact = () => {

    const [send, setSend] = useState(true);

    const [loading, setLoading] = useState(false);


    const headerC = {

        'Content-Type': 'application/json'
    }
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        email: '',
        message: ''
    });

    const handleButton = (s) => {
        setSend(s)
    }

    // document.getElementById('enviar').addEventListener('click', function () {
    //     let nombreEmpresa = document.getElementById('nombreEmpresa').value;
    //     let numero = document.getElementById('numero').value;
    //     let email = document.getElementById('email').value;
    //     let mensaje = document.getElementById('mensaje').value;
    //     console.log('Nombre o empresa: ' + nombreEmpresa);
    //     console.log('Número: ' + numero);
    //     console.log('Email: ' + email);
    //     console.log('Mensaje: ' + mensaje);
    //     let correo = `Nombre o empresa: ${nombreEmpresa} .%0ANumero: ${numero}.%0AEmail: ${email}. %0AMensaje:  ${mensaje} `
    //     window.location.href = `http://wa.me/59899380990/?text=${correo}`
    // })

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(formData.name)
        setLoading(true);
        try {
            let text = `Nombre: ${formData.name} \n\n Numero: ${formData.number} \n\n Email: ${formData.email} \n\n Mensaje: ${formData.message} `

            let jsonM = {
                'to': 'aajsalto@hotmail.com',
                'subject': 'De Contacto Agrope',
                'text': text
            }

            const response = await axios.post('https://app-tools.fly.dev/email/send', jsonM, {
                withCredentials: true,
                headers: headerC
            });
            // alert('Respuesta:' + response.data);
            // Realizar acciones adicionales con la respuesta si es necesario
        } catch (error) {
            alert('Error al enviar la solicitud:' + error);
            // Manejar errores si es necesario
        } finally {
            setLoading(false);
        }
    };
    const handleSubmitWpp = async (event) => {

        let nombreEmpresa = document.getElementById('nombreEmpresa').value;
        let numero = document.getElementById('numero').value;
        let email = document.getElementById('email').value;
        let mensaje = document.getElementById('mensaje').value;

        console.log('Nombre o empresa: ' + nombreEmpresa);
        console.log('Número: ' + numero);
        console.log('Email: ' + email);
        console.log('Mensaje: ' + mensaje);

        let correo = `Nombre o empresa: ${nombreEmpresa} .%0ANumero: ${numero}.%0AEmail: ${email}. %0AMensaje:  ${mensaje} `

        // window.location.href = `http://wa.me/59899380990/?text=${correo}`
        const urlWhatsApp = `http://wa.me/59899878569/?text=${correo}`
        window.open(urlWhatsApp, '_blank');
    };



    const handleChange = (event) => {
        // console.log(event.target.value)
        setFormData({ ...formData, [event.target.name]: event.target.value });

    };
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const wpp = (
        <div id="form-wpp">

            <div className="row d-flex justify-content-center" >
                <div className="col-12 col-lg-7 mt-3">
                    <h2 className="">Whats App</h2>

                    <div className="form-group">
                        <div className="form-group">
                            <label className="col-form-label mt-4" for="inputDefault">Nombre o empresa</label>
                            <input type="text" className="form-control" placeholder="" id="nombreEmpresa" />
                        </div>
                        <div className="form-group">
                            <label className="col-form-label mt-4" for="inputDefault">Numero</label>
                            <input type="text" className="form-control" placeholder="" id="numero" />
                        </div>
                        

                        <div className="form-group">
                            <label for="exampleInputEmail1" className="form-label mt-4">Email</label>
                            <input type="email" className="form-control" id="email" aria-describedby="emailHelp"
                                placeholder="Email" />

                        </div>



                        <div className="form-group">
                            <label for="exampleTextarea" className="form-label mt-4">Mensaje</label>
                            <textarea className="form-control" id="mensaje" rows="3"></textarea>
                        </div>

                        <button type="button " onClick={() => handleSubmitWpp()} id="enviar" className="btn btn-success mt-3">Enviar</button>
                    </div>
                </div>

            </div>
        </div>
    )
    const correo = (
        <div id="form-email">
            <div className="row d-flex justify-content-center" >

                <form className="col-12 col-lg-7 mt-3" id="form-email-send" name="contact" onSubmit={handleSubmit}  >
                    <h2 className="">Email</h2>
                    <fieldset>
                        <div className="form-group">

                            <div className="form-group">
                                <label className="col-form-label mt-4" for="inputDefault">Nombre o empresa</label>
                                <input type="text" name='name' onChange={handleChange} className="form-control" placeholder="" id="email-nombre-empresa" />
                            </div>
                            <div className="form-group">
                                <label className="col-form-label mt-4" for="inputDefault">Numero</label>
                                <input type="text" name='number' onChange={handleChange} className="form-control" placeholder="" id="email-numero" />
                            </div>
                            <div className="d-center">

                                {loading && <Loader />}
                            </div>

                            <div className="form-group">
                                <label for="exampleInputEmail1" className="form-label mt-4">Email</label>
                                <input type="email" name='email' onChange={handleChange} className="form-control" id="email-email" aria-describedby="emailHelp"
                                    placeholder="Email" />

                            </div>


                            <div className="form-group">
                                <label for="exampleTextarea" className="form-label mt-4">Mensaje</label>
                                <textarea className="form-control" name='message' onChange={handleChange} id="email-mensaje" rows="3"></textarea>
                            </div>

                            <button type="submit" id="" className="btn btn-success mt-3">Enviar</button>

                        </div>

                    </fieldset>
                </form>

            </div>
        </div  >
    )

    return (
        <div class="container">
            <h1 id="tituloContact">Contactanos</h1>
            <div >
                <img src={sponsor1} id="imgSponsor" alt="" />
            </div>
            <div >
                <p id="idSubTituloContatc" className="t-center ">¡Contáctanos para unirte como socio, conocernos o patrocinarnos!</p>
            </div>
            <div class="row mt-5">
                <div className="col-12 col-md-4 col-lg-4 InfoComunicacion">
                    <h3 className="t-center">Ubicación:</h3>
                    <p className="t-center"> Nuestra sede se ubica en Calle Amorín 55,<br />
                        Dentro de La Asociación Agropecuaria de Salto</p>

                </div>
                <div className="col-12 col-md-4 col-lg-4 InfoComunicacion ">
                    <h3 className="t-center">Visita Nuestras Redes Sociales </h3>
                    <div className="logoTextContact " >
                        <a className=" infoEnlaces" href="https://www.instagram.com/aajsalto/?hl=es" >
                            <img src={instagramLogo} alt="" height="30 px" width="30 px" />
                            <p className="textoContact"> @aajsalto</p>
                        </a>
                    </div>

                    <div className="logoTextContact" >
                        <a href="https://www.facebook.com/AAJSALTO" className="infoEnlaces">
                            <img src={facebookLogo} height="30 px" width="30px" alt="" />
                            <p className="textoContact" > AAj Salto</p>
                        </a>
                    </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 InfoComunicacion">
                    <h3 className="t-center">O Comunicate a:</h3>
                    <p className="t-center">Tel: 099 878 569</p>
                    <p className="t-center">mail: aajsalto@hotmail.com</p>
                </div>

            </div>

            <div className="formulario">

                <div className="container mt-5" id="contacto">
                    <div className="d-flex justify-content-center">
                        <button id="btn-email " className="btn btn-primary m-2" onClick={() => handleButton(true)}>Email</button>
                        <button className="btn btn-success m-2" id="btn-wpp" onClick={() => handleButton(false)}>Whats App</button>
                    </div>
                    {send ? (
                        
                        correo
                    ) : (
                        wpp
                    )}
                    {/* <!-- CORREO --> */}
                    { }

                </div>

            </div>
        </div>

    )
}
export default Contact;