


import ListPost from '../components/ListPost';
 import "../Styles/activitiesStyles.css"
const Activities=()=>{
    
    
    
    return(
    <div className='container conteinerActivitis'>
        <h1 id='idiTuloActividades'>Actividades</h1>

        <ListPost/>
    </div>
    )
}
export default Activities;