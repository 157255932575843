import { NavLink } from "react-router-dom";
import logoAgrope from "../archivosGraficos/logo-agrope.png"
import "../Styles/navHeader.css"
const NavBar = () => {


    const divStyle = {
        //backgroundColor:'rgba( 0, 100, 0,0.6)',
        fontWeight: "bold"
    }

    return (
       

            





                <nav class="navbar navbar-expand-lg navbar-light navStyles " id="div-nav">
                    

                    <div class="container-fluid " id='div-inner-nav'>
                        <div className="div-logo"  >
                            <img src={logoAgrope} width="140px" height="140px"  />
                        </div>
                        <div id="barra-diagonal">

                        </div>
                        <button class="navbar-toggler me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse justify-content-end" id="navbarNav">

                            <ul class="navbar-nav  fw-bold   ">
                                <li className="nav-item">
                                    <NavLink className="nav-link active" aria-current="page" to="/"><span className="navLink">Inicio</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Activities"><span className="navLink">Actividades</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/AboutUs"><span className="navLink">Sobre Nosotros</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Contact"><span className="navLink">Contacto</span></NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/SaltoOvino"><span className="navLink">Salto Ovino</span></NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
          

       

    )
}
export default NavBar;

