// import OvejaCorral1 from "../archivosGraficos/ContenidoSaltoOvino/OvejasCorral1.jpg"
// import OvejaCorral2 from "../archivosGraficos/ContenidoSaltoOvino/OvejasCorral2.jpg"
// import CorderoFAenado from "../archivosGraficos/ContenidoSaltoOvino/CorderoFaenado1.jpg"
// import ExpoAvicola from "../archivosGraficos/ContenidoSaltoOvino/ExpoAvicola1.jpg"
// import Asadores from "../archivosGraficos/ContenidoSaltoOvino/Asadores.jpg"
// import variosCorderos from "../archivosGraficos/ContenidoSaltoOvino/VarioCorderosFaena.jpg"
// import fotoJuraCorderos from "../archivosGraficos/ContenidoSaltoOvino/FotoJuraCorderos.jpeg"
import ConcursoBorregas from "../archivosGraficos/ContenidoSaltoOvino/SaltoOvino2023/ConcursoBorregas.jpg"
import ConcursoCorderoPesado from "../archivosGraficos/ContenidoSaltoOvino/SaltoOvino2023/ConcursoCorderosPesados.jpg"
import lanzamientoSaltoOvino from "../archivosGraficos/ContenidoSaltoOvino/SaltoOvino2023/lansamiento-salto-ovino.jpg"
import descargas from "../archivosGraficos/descargas.png"

import "../Styles/saltoOvinoStyles.css"
const SaltoOvino = () => {
    return (
        <div className="container">
            <div className="row   d-flex   justify-content-center   ">
                <div className="col col-lg-12 cont-img-lanzamiento">
                    <img src={lanzamientoSaltoOvino} alt="" />
                </div>
                <div>
                    <div className="" id="contentGuia">
                        <a href="https://www.aas.com.uy/sites/default/files/2023-09/CONFECCION%20DE%20GUIAS%20SALTO%20OVINO%202023.pdf" className="textALink" id="divGuia" target="_blank">
                            <img className="logo-descarga" src={descargas} alt="" />

                            CONFECCIÓN DE GUÍAS SALTO OVINO</a>
                    </div>
                </div>
            </div>
            <div className="row img-actividades-salto-ovino" >
                <div id="seccion-concurso-borregas" className="col col-lg-6 cont-img-actividades" >
                    <div className="div-capsula-imagen">
                        <img src={ConcursoBorregas} alt="" className="contImage" />
                    </div>

                    <div className="row">
                        <div className="col col-lg-6  div-link-descarga">
                            <a href="https://www.aas.com.uy/salto-ovino/Reglamento Concurso Borregas 2023.pdf" className="textALink " target="_blank">
                                <img className="logo-descarga" src={descargas} alt="" />

                                Reglamento Borregas</a>
                        </div>
                        <div className="col col-lg-6  div-link-descarga">
                            <a href="https://www.aas.com.uy/salto-ovino/FORMULARIO INSCRIPCION C.BORREGAS 2023.xlsx" className="textALink" target="_blank">
                                <img className="logo-descarga" src={descargas} alt="" />

                                Formulario Inscripcion Borregas</a>
                        </div>
                    </div>
                </div>
                <div id="seccion-cordero-pesado" className="col col-lg-6 cont-img-actividades">
                    <div className="div-capsula-imagen">
                        <img src={ConcursoCorderoPesado} alt="" className="contImage" />
                    </div>
                    <div className="row">
                        <div className="col col-lg-6 div-link-descarga">
                            <a href="https://www.aas.com.uy/salto-ovino/Reglamento Concurso Corderos 2023.pdf" className="textALink" target="_blank">
                                <img className="logo-descarga" src={descargas} alt="" />

                                Reglamento Cordero Pesado</a>
                        </div>
                        <div className="col col-lg-6 div-link-descarga">
                            <a href="https://www.aas.com.uy/salto-ovino/FORMULARIO INSCRIPCION C.CORDEROS 2023.xlsx" className="textALink" target="_blank">
                                <img className="logo-descarga" src={descargas} alt="" />

                                Formulario Inscripcion Cordero Pesado</a>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    )
}
export default SaltoOvino;