import "../Styles/posts.css"


const Post = (props) => {
  const post = props.post;

  return (
    <div className="contPost" >
      {/* <div className="card cardTamaño" >
        <div id="carouselExampleSlidesOnly" className="carousel slide" data-bs-ride="carousel">
          <div className="carousel-inner" >
            <div className="carousel-item active" data-bs-interval="3000">
              <img src={post.img1} className="d-block w-100 imgActivitis" alt="..." />
            </div>
            <div className="carousel-item" data-bs-interval="3000">
              <img src={post.img2} className="d-block w-100 imgActivitis" alt="..." />
            </div>

          </div>
        </div>

        <img src={post.img} className="card-img-top" />
        <div className="card-body card-body-actividades">
          <h5 className="card-title">{post.title}</h5>
          <p className="card-text">{post.text}</p>

        </div>
      </div> */}



      <div className=" row space-row">

        <div className="col-12 col-lg-6  ">
          <h2 className="d-center titulos-2 text-center">{post.title} </h2>
          <div className="d-center pt-4">
            <p className="texto-content  d-center">
            {post.text}</p>

          </div>
        </div>
        <div className="col-12 col-lg-6 d-center">
          <img src={post.img1} className="img-content-home" alt="" />
        </div>

      </div>

    </div>
  )
}
export default Post;


