// src/SwiperComponent.js
import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../Styles/cardSliderStyles.css'


import congreso from "../archivosGraficos/Novedades/inscripciones.jpg"

const CardSlider = () => {
  return (
    <Swiper className='swiper-hero'
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={30}
      slidesPerView={1}
      autoplay={{
        disableOnInteraction: true,
        delay: 3000,
        pauseOnMouseEnter: true,
      }}
      navigation
      loop={true}
      pagination={{
        clickable: true,
        dynamicBullets: true
      }
      }
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
    >
      <SwiperSlide>
        <div className="card card-slider" >
          <img src={congreso} className="card-img-top" alt="..." />
          <div className="card-body">
            <h5 className="card-title">Inscripciones Abiertas</h5>
            <p className="card-text"> -Construyamos juntos una comunidad de jóvenes interesada por
              el futuro agropecuario.</p>
              <p>Inscripciòn hasta el 14 de abril.</p>

          </div>
        </div>
      </SwiperSlide>


      {/* Agrega más SwiperSlides según sea necesario */}
    </Swiper>
  );
};

export default CardSlider;
