
import instagramLogo from "../archivosGraficos/logosSimples/instagramLogo.png"
import facebookLogo from "../archivosGraficos/logosSimples/facebookLogo.png"
import FotoJuraCorderos from "../archivosGraficos/Home/FotoJuraCorderos.jpeg"
import grupoAAJS from "../archivosGraficos/Home/grupoAAJS.jpg"
import charla from "../archivosGraficos/Home/charla.jpg"
import rancho from "../archivosGraficos/Home/rancho.jpg"
import destresasOvinas from "../archivosGraficos/Home/destresasOvinas.jpg"
import federacionRural from "../archivosGraficos/Home/federacionRural.jpg"
import "../Styles/home.css"

import CardSlider from "../components/CardSlider";

const Home = () => {

    return (
        <div>
            <div className="container-fluid " id="container-home">


                <div className="conten-image-1">
                    <div>
                        <div className="row justify-content-end">
                            
                            <div className="row col col-lg-4 col-xs-12 justify-content-end  pe-5">
                                <div className="col-6 logoText" >
                                    <a className=" infoEnlaces" href="https://www.instagram.com/aajsalto/?hl=es" >
                                        <img src={instagramLogo} alt="" height="30 px" width="30 px" />
                                        <p className="textoFooter-links"> @aajsalto</p>
                                    </a>
                                </div>

                                <div className="col-6 logoText" >
                                    <a href="https://www.facebook.com/AAJSALTO" className="infoEnlaces">
                                        <img src={facebookLogo} height="30 px" width="30px" alt="" />
                                        <p className="textoFooter-links" > AAj Salto</p>
                                    </a>

                                </div>
                            </div>

                        </div>

                        <div className="titulo-h1">
                            <h2 className="titulo-agrope p-4 animate__animated animate__backInUp">Asociación Agropecuaria de Jóvenes de Salto</h2>

                        </div>


                    </div>
                </div>
                {/* <div id="carouselExampleFade" class="carousel slide carousel-fade   " data-bs-ride="carousel">
                    <div class="carousel-inner">

                        <div class="carousel-item active" data-bs-interval="6000">
                            <img src={FotoCampo} class="d-block w-100 idImgHome" alt="..." />
                            <div className="carousel-caption  d-xs-block class-text-carrusel">
                                <h1 className="tituloHome">¿Quienes Somos?</h1>
                                <h5 className="textHome">Pertenecemos a una institución sin fines de lucro, orientada a la defensa y formación de jóvenes del sector agropecuario. </h5>

                            </div>
                        </div>

                        <div class="carousel-item" data-bs-interval="6000">
                            <img src={FotoCampo} class="d-block w-100 idImgHome" alt="..." />
                            <div className="carousel-caption d-xs-block class-text-carrusel ">
                                <h1 className="tituloHome">¿Que hacemos?</h1>
                                <h5 className="textHome">Apuntamos a actividades de capacitación, integración intra e interdepartamental con otros grupos y voluntariado vinculados a la juventud rural</h5>

                            </div>
                        </div>


                    </div>

                </div> */}

            </div>
            <div className="container">


                <div className="row container-quienes-somos mt-5">
                    <div className=" col-lg-6 ">
                        <h1 className="tituloHome">¿Quienes Somos?</h1>

                    </div>
                    <div className=" col-lg-6 ">

                        <h5 className="textHome">Pertenecemos a una institución sin fines de lucro, orientada a la defensa y formación de jóvenes del sector agropecuario. </h5>
                    </div>

                </div>
                <div className="row mt-5">
                    <div className=" col-lg-6  ">
                        <img src={rancho} alt="" className="img-quienes-somos" />
                    </div>
                    <div className=" col-lg-6  ">
                        <img src={grupoAAJS} alt="" className="img-quienes-somos" />

                    </div>

                </div>
                <div className="row container-quienes-somos mt-5">
                    <div className=" col-lg-6 ">
                        <h1 className="tituloHome">¿Que Hacemos?</h1>

                    </div>
                    <div className=" col-lg-6 ">

                        <h5 className="textHome">Apuntamos a actividades de capacitación, integración intra e interdepartamental con otros grupos y voluntariado vinculados con la juventud rural. </h5>
                    </div>

                </div>
                <div className="row mt-5">
                    <div className=" col-lg-6  ">
                        <img src={FotoJuraCorderos} alt="" className="img-quienes-somos" />
                    </div>
                    <div className=" col-lg-6  ">
                        <img src={destresasOvinas} alt="" className="img-quienes-somos" />

                    </div>

                    <div className=" col-lg-6 mt-4 ">
                        <img src={charla} alt="" className="img-quienes-somos" />
                    </div>
                    <div className=" col-lg-6 mt-4 ">
                        <img src={federacionRural} alt="" className="img-quienes-somos" />

                    </div>
                </div>






            </div>
            <div className="container-fluid fluid-img-2 mt-5">
                <div className="conten-image-2">

                    <p className="text-imagen-2">Forjando Futuros Rurales desde 1985: Explora la Asociación Agropecuaria de Jóvenes de Salto, un pilar gremial que defiende intereses agrarios y canaliza
                        la energía juvenil. Desde la conciencia ruralista hasta la participación activa, su legado transforma el agro y la comunidad.</p>


                </div>
            </div>
            <div className="container">
                <div className=" mt-5 ">
                    <h2 className="tituloHome" style={{ textAlign: 'center', margin: '40px' }}>
                        Novedades
                    </h2>

                    <CardSlider />

                </div>

            </div>
        </div>
    )
}

export default Home;